import axios from 'axios'
import { message } from 'ant-design-vue'
import routerIndex from '../router/index'

export const ENV = process.env.NODE_ENV === 'development'

// create an axios instance
const service = axios.create({
    timeout: 60000 // request timeout
})
let http = ''

if (ENV) {
    console.info('Request--开发环境')
    http = '/test-dataWorkers'
} else {
    console.info('Request--生产环境')
    http = location.hostname == 'b.test.wahool.com' ? '//data-workers.test.wahool.com/api' : '//data-workers.wahool.com/api'
}
const time_zone = 0 - parseInt(new Date().getTimezoneOffset() / 60)

export default {
    // eslint-disable-next-line no-unused-vars
    GET: (api, data, query, other) => {
        let fe_data_keep = data
        return new Promise((resolve, reject) => {
            let config = {
                params: data || {},
                headers: {
                    // Authentication: localStorage.getItem('token'),
                    locale: time_zone,
                },
            }
            if (query && query.responseType) {
                config['responseType'] = query.responseType
            }
            service
                .get(http + api, config)
                .then((response) => {
                    //下载文件方法
                    if (query && query.responseType) {
                        // let str2utf8 = window.TextEncoder ? function(str) {
                        //     let encoder = new TextEncoder('utf8')
                        //     let bytes = encoder.encode(str)
                        //     let result = ''
                        //     for(let i = 0; i < bytes.length; ++i) {
                        //         result += String.fromCharCode(bytes[i])
                        //     }
                        //     return result
                        // } : function(str) {
                        //     return eval('\''+encodeURI(str).replace(/%/gm, '\\x')+'\'')
                        // }
                        let link = document.createElement('a')
                        let blob = new Blob([response.data], {
                            type: 'application/vnd.ms-excel',
                        })
                        let objectUrl = window.URL.createObjectURL(blob)
                        link.href = objectUrl
                        let file_name
                        if (other && other.fileName) {
                            file_name = other.fileName
                            response.data.fe_id = data.businessId
                            response.data.fe_name = other.fileName
                            resolve(response.data)
                        } else {
                            file_name = response.headers['content-disposition'].split(';')[1].split('=')[1]
                            resolve(response.data)
                        }
                        console.log('file_name', file_name)
                        link.download = file_name
                        link.click()
                        window.URL.revokeObjectURL(objectUrl)
                        return
                    } else if (response.data.type === 'application/json') {
                        const reader = new FileReader()
                        reader.readAsText(response.data, 'utf-8')
                            // eslint-disable-next-line no-unused-vars
                        reader.onload = function() {
                            const readerres = reader.result
                                // eslint-disable-next-line no-unused-vars
                            const data = JSON.parse(readerres)
                            if (other) {
                                if (!other.closeFailurePrompt) {
                                    if (data.code !== 200) message.error(data.message)
                                }
                            } else {
                                if (data.code !== 200) message.error(data.message)
                            }
                            if (data.code === 599) goLogin()
                            if (other && other.fileName) {
                                resolve({
                                    ...data,
                                    fe_id: fe_data_keep.businessId,
                                    fe_name: other.fileName,
                                })
                            } else {
                                resolve(data)
                            }
                        }
                        return
                    }
                    if (response.data.code !== 200) message.error(response.data.message)
                    if (response.data.code === 599) goLogin()
                    resolve(response.data)
                })
                .catch((err) => {
                    console.log(
                        '\n'
                        .concat(` %c ${err}`, ' ')
                        .concat(` %c /api${api} `)
                        .concat(` %c ${JSON.stringify(data)} `, '\n', '\n'),
                        'color: #fadfa3; background: #030307; padding:5px 0;',
                        'background: #fadfa3; padding:5px 0;',
                        'background: #1890ff; padding:5px;color:#ffffff;'
                    )
                    if (other) {
                        if (!other.closeFailurePrompt) {
                            message.error('system error')
                        }
                    } else {
                        message.error('system error')
                    }
                    reject(err)
                })
        })
    },
    POST: (api, data, form) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: {
                    locale: time_zone,
                },
            }
            if (api !== '/admin/account/login') {
                config['headers'] = config['headers'] || {}
                config['headers']['Authentication'] = localStorage.getItem('token')
            }
            if (form) {
                config['headers'] = config['headers'] || {}
                config['headers']['Content-Type'] = 'application/x-www-form-urlencoded'
            }
            service
                .post(http + api, data, config)
                .then((response) => {
                    if (response.data.code !== 200) message.error(response.data.message)
                    if (response.data.code === 599) goLogin()
                    resolve(response.data)
                })
                .catch((err) => {
                    console.log(
                        '\n'
                        .concat(` %c ${err}`, ' ')
                        .concat(` %c /api${api} `)
                        .concat(` %c ${JSON.stringify(data)} `, '\n', '\n'),
                        'color: #fadfa3; background: #030307; padding:5px 0;',
                        'background: #fadfa3; padding:5px 0;',
                        'background: #1890ff; padding:5px;color:#ffffff;'
                    )
                    message.error('system error')
                    reject(err)
                })
        })
    },
    PUT: (api, data) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: {
                    Authentication: localStorage.getItem('token'),
                    locale: time_zone,
                },
            }
            service
                .put(http + api, data, config)
                .then((response) => {
                    if (response.data.code !== 200) message.error(response.data.message)
                    resolve(response.data)
                })
                .catch((err) => {
                    console.log(
                        '\n'
                        .concat(` %c ${err}`, ' ')
                        .concat(` %c /api${api} `)
                        .concat(` %c ${JSON.stringify(data)} `, '\n', '\n'),
                        'color: #fadfa3; background: #030307; padding:5px 0;',
                        'background: #fadfa3; padding:5px 0;',
                        'background: #1890ff; padding:5px;color:#ffffff;'
                    )
                    message.error('system error')
                    reject(err)
                })
        })
    },
    DELETE: (api, data) => {
        let url = http + api
        return new Promise((resolve, reject) => {
            // axios.delete(http + api,  {data: (data || {}),headers: { Authentication: localStorage.getItem('token')}})
            service({
                    method: 'delete',
                    url,
                    data,
                    headers: {
                        Authentication: localStorage.getItem('token'),
                        locale: time_zone,
                    },
                })
                .then((response) => {
                    if (response.data.code !== 200) message.error(response.data.message)
                    resolve(response.data)
                })
                .catch((err) => {
                    console.log(
                        '\n'
                        .concat(` %c ${err}`, ' ')
                        .concat(` %c /api${api} `)
                        .concat(` %c ${JSON.stringify(data)} `, '\n', '\n'),
                        'color: #fadfa3; background: #030307; padding:5px 0;',
                        'background: #fadfa3; padding:5px 0;',
                        'background: #1890ff; padding:5px;color:#ffffff;'
                    )
                    message.error('system error')
                    reject(err)
                })
        })
    },
}

function goLogin() {
    routerIndex.push('/login')
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('userName')
    window.localStorage.removeItem('userAvatar')
}
// let lock = false;
service.interceptors.response.use(
    function(response) {
        // if(localStorage.getItem('indexmd5')){
        //     if(localStorage.getItem('indexmd5') !== response.headers.indexmd5 && !lock) {
        //         lock = true;
        //         window.localStorage.removeItem('indexmd5')
        //         window.location.reload(true);

        //     }
        // }else {
        //     localStorage.setItem('indexmd5',response.headers.indexmd5);
        // }
        return response
    },
    function(error) {
        return Promise.reject(error)
    }
)